<!--
 * @Description: 单图上传
 * @Author: 琢磨先生
 * @Date: 2023-12-15 10:26:46
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-03 17:10:05
-->
<template>
  <div class="upload-box">
    <el-upload
      class="avatar-uploader"
      action=""
      :show-file-list="false"
      :http-request="onUpload"
      accept=".jpg,.jpeg,.png,.gif"
    >
      <el-image
        v-if="imageUrl"
        :src="imageUrl"
        class="preview"
        fit="fill"
      ></el-image>
      <el-icon v-else class="avatar-uploader-icon"><Upload /></el-icon>
      <div class="progress" v-if="saving">
        <el-progress type="circle" :width="90" :percentage="percentage" />
      </div>
    </el-upload>
    <div class="original-box" v-if="originalUrl">
      <el-image
        :src="originalUrl"
        class="avatar"
        fit="fill"
        :initial-index="0"
        :preview-src-list="srcList"
      ></el-image>
      <el-button
        class="btn-delete"
        type="danger"
        link
        icon="delete"
        @click="onDelete"
      ></el-button>
    </div>
  </div>
</template>

<script>
import md5_util from "@/utils/md5_util.js";
import axios from "axios";

export default {
  data() {
    return {
      imageUrl: null,
      saving: false,
      percentage: 0,
      originalUrl: "",
      srcList: [],
    };
  },
  props: {
    src: String,
    ak: String,
    bucket: {
      type: String,
      default: "image",
    },
  },
  emits: ["close", "change"],
  watch: {
    src: {
      handler() {
        this.originalUrl = this.src;
        if (this.src) {
          this.imageUrl = null;
        }
        this.srcList = [this.src];
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * 上传
     */
    onUpload(ctx) {
      //处理预览
      let reader = new FileReader();
      reader.readAsDataURL(ctx.file);
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.uploadMfsHandler(ctx.file);
      };

      this.saving = true;
    },

    uploadMfsHandler: async function (file) {
      var self = this;
      //获取md5值
      var md5 = await md5_util.fileMd5(file);
      //获取图片长宽
      await this.handlerWH(file);

      var model = {
        height: file.height,
        width: file.width,
        size: file.size,
        mfsType: 0,
        name: file.name,
        ext: `.${file.type.replace("image/", "")}`,
        md5: md5,
      };

      var res = await this.$http.get("admin/v1/common/mfs/file_md5?md5=" + md5);
      if (res.code == 0) {
        if (res.data) {
          this.saving = false;
          this.percentage = 100.0;
          this.$emit("change", res.data.fileUrl, this.ak);
          return;
        }
      }

      this.$http
        .post("admin/v1/common/mfs/get_put_url", {
          bucket: this.bucket,
          ext: model.ext,
        })
        .then((res) => {
          if (res.code == 0 && res.data) {
            axios
              .put(res.data.upload_url, file, {
                headers: { "content-type": file.type },
                onUploadProgress(m) {
                  self.percentage = m.progress * 100;
                },
              })
              .then((response) => {
                if (response.status == 200) {
                  self.$emit("change", res.data.download_url, self.ak);
                  model.fileUrl = res.data.download_url;
                  self.$http.post("admin/v1/common/mfs/add", model);
                }
              });
          }
        })
        .finally(() => (this.saving = false));
    },

    /**
     * 处理获取图片的宽高
     */
    handlerWH(file) {
      return new Promise((resolve) => {
        let img = new Image();
        img.onload = function () {
          file.width = img.width;
          file.height = img.height;
          resolve(file);
        };
        img.src = URL.createObjectURL(file);
      });
    },

    /**
     * 删除原有图片,并回调空字符串地址
     */
    onDelete() {
      this.originalUrl = null;
      this.$emit("change", "", this.ak);
    },
  },
};
</script>

<style scoped>
.upload-box {
  width: 120px;
  height: 120px;
  position: relative;
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}
.avatar-uploader {
  position: relative;
}
.original-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.upload-box .avatar {
  width: 120px;
  height: 120px;
  /* position: absolute; */
  /* z-index: 1; */
  /* top: 0; */
  /* left: 0; */
}

.upload-box .original-box .btn-delete {
  position: absolute;
  right: 0;
  top: 0;
}

.progress {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style>
.avatar-uploader .el-progress__text {
  color: white;
}

/* .avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
} */

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  text-align: center;
}
</style>
